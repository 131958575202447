import React, { useEffect, useState } from 'react';
import { ChainflipLogo } from '@/shared/assets/chainflip-logo';
import { Link } from '@/shared/components';
import { ChainflipNetworkBadge } from '@/shared/components/atoms/ChainflipNetworkBadge';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import { CloseIcon, HamburgerIcon } from '@/shared/icons/large';
import { baseChainflipAssets } from '@/shared/utils/chainflip';
import { PolkaConnectInfo } from './PolkaConnectInfo';

const NavLinks = () => {
  const { selectedAccount } = usePolkadot();

  if (selectedAccount) {
    return (
      <>
        <Link href="/dashboard">Dashboard</Link>
        <Link href={`/market/${baseChainflipAssets[0]}`}>Positions</Link>
        <Link href={process.env.NEXT_PUBLIC_EXPLORER_URL as string} target="_blank">
          Block Explorer
        </Link>
        <PolkaConnectInfo />
      </>
    );
  }
  return (
    <Link href={process.env.NEXT_PUBLIC_EXPLORER_URL as string} target="_blank">
      Explorer
    </Link>
  );
};

export const Navbar = (): JSX.Element => {
  const [openNavModal, setOpenNavModal] = useState(false);

  useEffect(() => {
    if (openNavModal) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [openNavModal]);

  return (
    <div className="z-10 flex h-[86px] w-full flex-col items-center justify-center py-6">
      {!openNavModal && (
        <div className="flex w-full items-center justify-between space-x-3 px-8 2xl:container 2xl:px-0">
          <div className="flex items-center space-x-[25px]">
            <Link href="/">
              <ChainflipLogo />
            </Link>
            <ChainflipNetworkBadge />
          </div>
          <div className="hidden items-center space-x-12 text-14 text-cf-light-3 md:flex">
            <NavLinks />
          </div>
          <div onClick={() => setOpenNavModal(true)} className="cursor-pointer md:hidden">
            <HamburgerIcon />
          </div>
        </div>
      )}
      {openNavModal && (
        <>
          <div className="flex w-full items-center justify-between px-8">
            <div className="flex items-center space-x-[25px]">
              <Link href="/">
                <ChainflipLogo />
              </Link>
              <ChainflipNetworkBadge />
            </div>
            <button type="button" className="ml-auto" onClick={() => setOpenNavModal(false)}>
              <CloseIcon />
            </button>
          </div>
          <div className="fixed top-20 z-20 flex h-full w-full flex-col  bg-black px-8 py-4">
            <div className="flex flex-col space-y-8">
              <div className="flex flex-col [&>a]:border-b [&>a]:border-b-cf-gray-3 [&>a]:py-5">
                <NavLinks />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
