import { useState } from 'react';
import { Button, ChainTokenLogo, Link } from '@/shared/components';
import { useFundingSDK } from '@/shared/hooks';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import { CloseIcon } from '@/shared/icons/large';
import { WindowIcon } from '@/shared/icons/small';
import { TokenAmount, abbreviate, etherscanUrl, chainflipAssetMap } from '@/shared/utils';
import SuccessHero from '../../assets/svg/success-circular-banner.svg';
import { BrokerModal } from '../BrokerModal';
import { ConnectAndFundWalletButton } from '../ConnectAndFundWalletButton';
import TokenInputField from '../TokenInputField';

interface FundAccountModalProps {
  open: boolean;
  onClose: () => void;
}

export const FundAccountModal = ({ open, onClose }: FundAccountModalProps): JSX.Element => {
  const token = chainflipAssetMap.Flip;

  const { flipBalance, minimumFunding } = useFundingSDK();

  const [inputValue, setInputValue] = useState(
    minimumFunding?.max(TokenAmount.fromWholeUnits(10)).toFixed() ?? '10',
  );
  const [fundTxHash, setFundTxHash] = useState<string | null>(null);

  const { selectedAccount: polkadotAccount } = usePolkadot(true);
  const minimumAmount = minimumFunding ?? TokenAmount.fromWholeUnits(10);

  const balanceTokenAmount = TokenAmount.from(flipBalance) ?? undefined;

  const close = () => {
    setFundTxHash(null);
    onClose();
  };

  const fundingForm = (
    <div className="flex flex-col space-y-9">
      <div className="space-y-1">
        <div className="cf-gray-gradient flex font-aeonikMedium text-20">
          Fund account with {token.symbol}
        </div>
        <div className="w-[288px] text-14 font-normal leading-[18px] text-cf-light-2">
          This <span className="text-cf-light-4">{token.symbol}</span> is used to pay the gas fees
          required to open deposit channels.{' '}
          <Link
            href="https://docs.chainflip.io/concepts/swaps-amm/deposit-channels-and-brokers"
            target="_blank"
            className="underline"
          >
            Learn more
          </Link>
        </div>
      </div>
      <div>
        <TokenInputField
          token={chainflipAssetMap.Flip}
          inputValue={inputValue}
          setInputValue={setInputValue}
          balance={balanceTokenAmount}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button size="medium" type="secondary-standard" onClick={close}>
          Cancel
        </Button>
        <ConnectAndFundWalletButton
          className="font-aeonikMedium !text-14"
          token={chainflipAssetMap.Flip}
          tokenAmount={TokenAmount.fromWholeUnits(inputValue || 0)}
          minimumAmount={minimumAmount}
          onFunded={setFundTxHash}
        />
      </div>
    </div>
  );
  const successContent = (
    <>
      <SuccessHero className="pointer-events-none absolute m-[-20px]" />
      <div className="flex flex-col items-center space-y-5 pb-8 pt-24 text-white">
        <div className="flex flex-col items-center space-y-1 font-aeonikMedium">
          You funded your account with
          <div className="cf-gray-gradient flex items-center gap-x-2 font-aeonikBold text-[32px]">
            <ChainTokenLogo token={token} /> {inputValue} {token.symbol}
          </div>
          <div>to {abbreviate(polkadotAccount.address)}</div>
        </div>
        <Button onClick={close}>Return to Dashboard</Button>
        <Link
          target="_blank"
          href={`${etherscanUrl()}/tx/${fundTxHash}`}
          className="flex items-center gap-x-1 font-aeonikMono text-12 text-cf-light-3"
        >
          <WindowIcon />
          View on explorer
        </Link>
      </div>
    </>
  );

  return (
    <BrokerModal open={open} onClose={close}>
      <div className="bg-holy-radial-gray-3-60 flex w-[487px] flex-col rounded-md border border-cf-gray-4 p-5 backdrop-blur-[6px]">
        <button type="button" className="absolute self-end" onClick={close}>
          <CloseIcon />
        </button>
        {fundTxHash == null ? fundingForm : successContent}
      </div>
    </BrokerModal>
  );
};
