import { startTransition } from 'react';
import { useRouter } from 'next/router';
import { Button, CopyButton, FlipLabel } from '@/shared/components';
import { ConnectAvatar } from '@/shared/components/RainbowKit';
import useBoolean from '@/shared/hooks/useBoolean';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import { PowerIcon } from '@/shared/icons/large';
import { abbreviate, chainflipAssetMap, TokenAmount } from '@/shared/utils';
import { FundAccountModal } from './Dashboard/FundAccountModal';
import RedeemModal from './Dashboard/RedeemModal';
import NavbarDropdown from './NavbarDropdown';
import { useStateChainAccount } from '../hooks/useStateChainAccount';

export const PolkaConnectInfo = () => {
  const router = useRouter();
  const { selectAccount, selectedAccount: polkadotAccount } = usePolkadot(true);
  const { flipBalance } = useStateChainAccount();
  const address = polkadotAccount.id;

  const {
    value: isActive,
    setTrue: activateDropdown,
    setFalse: deactivateDropdown,
  } = useBoolean(false);

  const {
    value: fundAccountOpened,
    setTrue: openFundAccountModal,
    setFalse: closeFundAccountModal,
  } = useBoolean(false);

  const {
    value: redeemModalOpen,
    setTrue: openRedeemModal,
    setFalse: closeRedeemModal,
  } = useBoolean(false);

  const token = chainflipAssetMap.Flip;

  return (
    <>
      <NavbarDropdown
        title={
          <Button type="secondary-standard" className="flex items-center gap-x-2 bg-transparent">
            {abbreviate(address)}
            <ConnectAvatar address={address} size={16} />
          </Button>
        }
        items={[
          {
            content: (
              <div className="flex flex-col">
                <span className="text-cf-light-2">Account</span>
                <div className="flex flex-row items-center justify-start gap-x-1">
                  {abbreviate(address)}
                  <CopyButton textToCopy={address} />
                </div>
              </div>
            ),
          },
          {
            content: (
              <div className="flex flex-col">
                <span className="text-cf-light-2">{token.symbol} Balance</span>
                <FlipLabel large amount={flipBalance ?? TokenAmount.ZERO} symbol={false} precise />
              </div>
            ),
          },
          {
            content: <span className="font-aeonikMedium">Fund account</span>,
            onClick: openFundAccountModal,
          },
          {
            content: <span className="font-aeonikMedium">Redeem funds</span>,
            onClick: openRedeemModal,
          },
          {
            content: (
              <div className="flex items-center gap-x-1 font-aeonikMedium">
                <PowerIcon className="text-cf-red-1" />
                Disconnect
              </div>
            ),
            separator: <hr className="border-cf-gray-4" />,
            onClick: () =>
              startTransition(() => {
                selectAccount(null);
                router.push('/');
              }),
          },
        ]}
        isActive={isActive}
        onMouseEnter={activateDropdown}
        onMouseLeave={deactivateDropdown}
      />

      <FundAccountModal onClose={closeFundAccountModal} open={fundAccountOpened} />
      <RedeemModal onClose={closeRedeemModal} open={redeemModalOpen} />
    </>
  );
};
