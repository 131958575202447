import { useEffect, useMemo, useRef, useState } from 'react';
import { type PendingRedemption } from '@chainflip/sdk/funding';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import { ethereum } from '@/shared/assets/chains';
import { validateEvmAddress } from '@/shared/assets/chains/addressValidation';
import { Button, ChainTokenLogo, Link, WarningModal } from '@/shared/components';
import { useFundingSDK } from '@/shared/hooks';
import useBoolean from '@/shared/hooks/useBoolean';
import { usePolkadot } from '@/shared/hooks/usePolkadot';
import useSteps from '@/shared/hooks/useSteps';
import useToast from '@/shared/hooks/useToast';
import Information from '@/shared/icons/flip-ui-kit/large/Information';
import { CloseIcon } from '@/shared/icons/large';
import { WindowIcon } from '@/shared/icons/small';
import { PENDING_REDEMPTION } from '@/shared/queryKeys';
import {
  TokenAmount,
  abbreviate,
  etherscanUrl,
  chainflipAssetMap,
  FLIP_SYMBOL,
} from '@/shared/utils';
import { tryGetEthersError } from '@/shared/utils/functions';
import RedemptionPending from './RedemptionPending';
import CheckContract from '../../../assets/svg/circular-shadow-contract-check.svg';
import SuccessHero from '../../../assets/svg/success-circular-banner.svg';
import { useStateChainAccount } from '../../../hooks/useStateChainAccount';
import AddressInput from '../../AddressInput';
import { BrokerModal } from '../../BrokerModal';
import TokenInputField from '../../TokenInputField';

const ConnectWallet = () => (
  <div className="flex h-full w-full items-end justify-end">
    <ConnectButton.Custom>
      {({ openConnectModal }) => (
        <Button size="small" onClick={openConnectModal}>
          Connect Ethereum wallet
        </Button>
      )}
    </ConnectButton.Custom>
  </div>
);

const WaitForRedemption = () => {
  const {
    selectedAccount: { idHex },
  } = usePolkadot(true);
  const { fundingSDK } = useFundingSDK();
  const { data: pendingRedemption } = useQuery({
    queryKey: ['pending-redemption', idHex],
    queryFn: async () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (await fundingSDK!.getPendingRedemption(idHex)) ?? null,
    refetchInterval: 5000,
    enabled: fundingSDK !== undefined,
  });

  const hasRedemption = useRef(false);

  if (pendingRedemption) {
    hasRedemption.current = true;
  }

  const toast = useToast('waiting-for-redemption');

  useEffect(() => {
    const loadingMessage = 'Waiting for redemption on Ethereum network...';
    toast.loading(loadingMessage);

    return () => {
      if (hasRedemption.current) {
        toast.success('Redemption confirmed pending on Ethereum network');
      } else {
        toast.dismiss();
      }
    };
  }, []);

  return (
    <div className="relative flex h-full w-full items-center justify-center">
      <CheckContract className="absolute" />
    </div>
  );
};

export default function RedeemModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const {
    selectedAccount: { idHex },
  } = usePolkadot(true);
  const ethAccount = useAccount();
  const [address, setAddress] = useState(ethAccount.address ?? '');
  const [amount, setAmount] = useState('');
  const { account, sendExtrinsic } = useStateChainAccount();
  const { fundingSDK, redemptionDelayInMilliseconds } = useFundingSDK();
  const [successTx, setSuccessTx] = useState<string>();

  const {
    value: showWarningModal,
    setTrue: openWarningModal,
    setFalse: closeWarningModal,
  } = useBoolean(false);

  const [step, nextStep, setStep] = useSteps(
    'create-redemption',
    'wait-for-pending',
    'redemption-pending',
    'redemption-success',
  );
  const [redemption, setRedemption] = useState<PendingRedemption>();

  const { data: pendingRedemption } = useQuery({
    queryKey: [PENDING_REDEMPTION, idHex, Boolean(fundingSDK)],
    queryFn: async () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (await fundingSDK!.getPendingRedemption(idHex)) ?? null,
    refetchInterval: 5000,
    enabled: open && fundingSDK !== undefined,
  });

  const {
    data: redemptionTax = TokenAmount.fromWholeUnits(5),
    status,
    refetch,
  } = useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => new TokenAmount(await fundingSDK!.getRedemptionTax()),
    queryKey: ['redemptionTax'],
    enabled: fundingSDK !== undefined,
    retryDelay: 1000,
  });
  useEffect(() => {
    if (fundingSDK && status !== 'success') refetch();
  }, [fundingSDK, status]);

  const isValidAddress = useMemo(() => validateEvmAddress(address), [address]);

  const maxAmount = account?.flip_balance.saturatingSub(redemptionTax) ?? TokenAmount.ZERO;
  const redeemExtrinsic = 'redeem-flip' as const;
  const redeemToast = useToast(redeemExtrinsic);
  const { mutate: executeRedemption, isPending: redemptionPending } = useMutation({
    mutationFn: (redeemAmount: 'Max' | TokenAmount) => {
      redeemToast.loading('Submitting redemption request...');
      if (redeemAmount === 'Max') {
        return sendExtrinsic(redeemExtrinsic, 'Max', address);
      }
      return sendExtrinsic(redeemExtrinsic, { Exact: redeemAmount.toString() }, address);
    },
    onSuccess: () => {
      redeemToast.success('Redemption request submitted');
      nextStep();
    },
    onError: (error) => {
      redeemToast.error('Error submitting redemption request', {
        description: tryGetEthersError(error, 'Redemption request'),
      });
    },
  });

  const submitRedemption = () => {
    const isMax = maxAmount.toFixed() === amount;
    if (isMax) {
      return openWarningModal();
    }
    return executeRedemption(TokenAmount.fromWholeUnits(amount));
  };

  useEffect(() => {
    if (pendingRedemption) {
      setRedemption(pendingRedemption);
      setStep('redemption-pending');
    }
  }, [pendingRedemption, open]);

  if (!account) return null;

  let title = 'Redeem FLIP';
  let body;
  let subtitle;

  if (showWarningModal) {
    return (
      <WarningModal
        isOpen={showWarningModal}
        onAccept={() => {
          closeWarningModal();
          executeRedemption('Max');
        }}
        onClose={() => {
          closeWarningModal();
        }}
        title="Redeem FLIP"
        content={
          <>
            <span className="text-cf-light-2">If you run out of FLIP, you will</span>
            <span className="text-cf-white">
              {' '}
              not be able to withdraw any of your current balances.
            </span>
          </>
        }
      />
    );
  }

  if (fundingSDK && step === 'create-redemption') {
    body = (
      <>
        <div className="flex w-full items-center space-x-1">
          <Information className="text-cf-blue-2" />
          <span className="text-12 text-cf-white">
            The network charges {redemptionTax.toFixedDisplay()} {FLIP_SYMBOL} per redemption
            request to prevent spam.
          </span>
        </div>
        <TokenInputField
          token={chainflipAssetMap.Flip}
          inputValue={amount}
          setInputValue={setAmount}
          balance={account.flip_balance}
          maxAmount={maxAmount}
          subtext={`+${redemptionTax.toFixed()} ${chainflipAssetMap.Flip.symbol} (Redemption tax)`}
        />
        <AddressInput
          label="Ethereum address"
          chain={ethereum}
          address={address}
          setAddress={setAddress}
          isValidAddress={isValidAddress}
        />
        <div className="flex w-full justify-end">
          <Button
            loading={redemptionPending && 'iconOnly'}
            size="small"
            disabled={!isValidAddress || amount === ''}
            onClick={submitRedemption}
          >
            Redeem
          </Button>
        </div>
      </>
    );
    subtitle = 'Submit your redemption request to the State Chain to begin the redemption process.';
  } else if (step === 'wait-for-pending') {
    body = <WaitForRedemption />;
    subtitle =
      'Your redemption request was submitted successfully to the state chain. Please hang tight while we wait for it to be submitted to the Ethereum network';
  } else if (step === 'redemption-pending' && fundingSDK && redemption && idHex) {
    body = (
      <RedemptionPending
        idHex={idHex}
        fundingSDK={fundingSDK}
        redemption={redemption}
        redemptionDelayInMilliseconds={redemptionDelayInMilliseconds}
        setSuccessTx={setSuccessTx}
        onSuccess={() => {
          setStep('redemption-success');
        }}
      />
    );
  } else if (step === 'redemption-success') {
    title = '';
    body = (
      <div className="relative flex h-full w-full flex-col items-center">
        <SuccessHero className="pointer-events-none absolute m-[-76px]" />
        <div className="mt-20 flex flex-col items-center space-y-5 text-white">
          <div className="flex flex-col items-center space-y-1 font-aeonikMedium">
            You redeemed
            <div className="cf-gray-gradient flex items-center gap-x-2 font-aeonikBold text-[32px]">
              <ChainTokenLogo token={chainflipAssetMap.Flip} />{' '}
              {new TokenAmount(redemption?.amount ?? 0).toFixed()} {chainflipAssetMap.Flip.symbol}
            </div>
            <div>to {abbreviate(address)}</div>
          </div>
          <Button onClick={onClose}>Return to Dashboard</Button>
          <Link
            target="_blank"
            href={`${etherscanUrl()}/tx/${successTx}`}
            className="flex items-center gap-x-1 font-aeonikMono text-12 text-cf-light-3"
          >
            <WindowIcon />
            View on explorer
          </Link>
        </div>
      </div>
    );
  } else {
    body = <ConnectWallet />;
    subtitle = 'Connect your Ethereum wallet to start the process.';
  }

  return (
    <BrokerModal
      open={open}
      onClose={onClose}
      afterLeave={() => {
        setStep('create-redemption');
        setRedemption(undefined);
      }}
    >
      <div className="flex h-[438px] w-[487px] flex-col space-y-2.5">
        <div className="bg-holy-radial-gray-3-60 flex h-full w-full flex-col items-center justify-center space-y-8 rounded-md border border-cf-gray-4 p-5 backdrop-blur-[6px]">
          <div className="flex w-full items-start justify-between">
            <div className="flex flex-1 flex-col space-y-2">
              <span className="cf-gray-gradient font-aeonikMedium text-20">{title}</span>
              <div className="text-14 text-cf-light-2">{subtitle}</div>
            </div>
            <button type="button" onClick={onClose} className="outline-cf-gray-5">
              <CloseIcon />
            </button>
          </div>
          {body}
        </div>
      </div>
    </BrokerModal>
  );
}
