import { useState } from 'react';
import { type FundingSDK, type PendingRedemption } from '@chainflip/sdk/funding';
import { useMutation } from '@tanstack/react-query';
import { formatDistanceToNow, fromUnixTime, isAfter } from 'date-fns';
import { Button, Countdown } from '@/shared/components';
import { useInterval } from '@/shared/hooks';
import useToast from '@/shared/hooks/useToast';
import { CalendarIcon, ClockIcon } from '@/shared/icons/large';
import { addToGoogleCalendar, FLIP_SYMBOL } from '@/shared/utils';
import { tryGetEthersError } from '@/shared/utils/functions';

const RedemptionPending = ({
  redemption,
  fundingSDK,
  idHex,
  onSuccess,
  redemptionDelayInMilliseconds,
  setSuccessTx,
}: {
  redemption: PendingRedemption;
  fundingSDK: FundingSDK;
  idHex: `0x${string}`;
  onSuccess: () => void;
  redemptionDelayInMilliseconds: number | undefined;
  setSuccessTx: (tx: string) => void;
}) => {
  const toast = useToast('execute-redemption');
  const [now, setNow] = useState(Date.now());

  const { mutate: executeRedemption, isPending } = useMutation({
    mutationFn: (_: React.MouseEvent) => {
      toast.loading('Executing redemption...');

      return fundingSDK.executeRedemption(idHex);
    },
    onSuccess: (data) => {
      setSuccessTx(data);
      onSuccess();
      toast.success('Redemption executed successfully');
    },
    onError: (error) => {
      toast.error('Failed to execute redemption', {
        description: tryGetEthersError(error, 'Redemption execution'),
      });
    },
  });

  const redemptionDelayInWords = redemptionDelayInMilliseconds
    ? formatDistanceToNow(Date.now() + redemptionDelayInMilliseconds)
    : '2 days';

  useInterval(() => {
    setNow(Date.now());
  }, 1000);

  const executableRedemptionStartTimestamp = fromUnixTime(Number(redemption.startTime));
  const executableRedemptionExpiryTime = fromUnixTime(Number(redemption.expiryTime));

  const isStartTimeElapsed = isAfter(now, executableRedemptionStartTimestamp);
  const isExpiryTimeElapsed = isAfter(now, executableRedemptionExpiryTime);

  return (
    <div className="flex h-full w-full flex-col justify-between">
      {!isStartTimeElapsed && (
        <>
          <div className="space-y-2">
            <p className="w-full font-aeonikMedium text-24 text-cf-white">Waiting to redeem</p>
            <p className="text-14">
              A waiting period of {redemptionDelayInWords} is required before redeeming funds to
              limit the risk in Chainflip&apos;s vaults.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <button
              type="button"
              className="mt-4 flex items-center justify-center rounded-md bg-cf-gray-3 px-4 py-2 text-12"
              onClick={() =>
                addToGoogleCalendar(
                  redemption.amount,
                  executableRedemptionStartTimestamp,
                  executableRedemptionExpiryTime,
                )
              }
            >
              <span className="px-2">
                <CalendarIcon />
              </span>
              Add to Google Calendar
            </button>
            <Countdown now={now} timestamp={executableRedemptionStartTimestamp} />
          </div>
        </>
      )}
      {isStartTimeElapsed && !isExpiryTimeElapsed && (
        <>
          <div className="space-y-2 text-left">
            <p className="text-14">
              You are all set! Redeem your {FLIP_SYMBOL} before the expiration time, otherwise it
              will be credited back to your Validator balance.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="flex items-center space-x-2 rounded-md bg-cf-orange-4 px-4 py-1 text-cf-orange-1">
              <ClockIcon width="18" height="18" />
              <div className="text-14 text-cf-orange-1">Your redemption expires in:</div>
            </div>
            <Countdown now={now} timestamp={executableRedemptionExpiryTime} />
          </div>
        </>
      )}
      {isExpiryTimeElapsed && (
        <>
          <div className="space-y-2">
            <p className="font-aeonikMedium text-24 text-cf-white">Redemption expired</p>
            <p className="text-14">
              Your redemption has expired. You must first refund the {FLIP_SYMBOL} to your state
              chain account before starting the redemption process again.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="flex items-center space-x-2 rounded-md border border-cf-red-1/20 bg-cf-red-4 px-4 py-1 text-cf-red-1">
              <ClockIcon width="18" height="18" />
              <span className="text-14">
                Your redemption expired on {executableRedemptionExpiryTime.toLocaleDateString()} at{' '}
                {executableRedemptionExpiryTime.toLocaleTimeString()}
              </span>
            </div>
            <Countdown duration={{}} />
          </div>
        </>
      )}
      <Button
        className="self-end"
        loading={isPending && 'iconOnly'}
        size="small"
        disabled={!isStartTimeElapsed || isPending}
        onClick={executeRedemption}
      >
        {!isExpiryTimeElapsed ? 'Redeem' : `Refund ${FLIP_SYMBOL} to your balance`}
      </Button>
    </div>
  );
};

export default RedemptionPending;
