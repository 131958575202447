import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { type Chain } from '@/shared/assets/chains';
import { chainTransparentLogo } from '@/shared/assets/chains/logo';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { WalletIcon } from '@/shared/icons/large';

export default function AddressInput({
  label,
  tooltipMessage,
  chain,
  address,
  setAddress,
  isValidAddress,
}: {
  label: string;
  tooltipMessage?: string;
  chain: Chain;
  address: string;
  setAddress: (address: string) => void;
  isValidAddress: boolean;
}) {
  const [isDirty, setIsDirty] = useState(false);
  const showError = isDirty && !isValidAddress;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setIsDirty(true);
    const newValue = e.target.value;
    if (/^[0-9a-z]*$/i.test(newValue)) {
      setAddress(newValue);
    }
    if (newValue === '') setIsDirty(false);
  }, []);

  return (
    <div className="w-full space-y-4">
      <div className="flex items-center text-16 text-cf-light-2">
        <div className="flex items-center space-x-3 p-1">
          <WalletIcon className="mx-[-5px] text-cf-green-1" />
          <span className="text-14">{label}</span>
          {tooltipMessage && <QuestionMarkTooltip content={tooltipMessage} disabled={false} />}
        </div>
        <div className="ml-auto flex items-center space-x-1 rounded-full border border-cf-gray-5 bg-cf-gray-4 px-2 py-1 text-12 text-white">
          {chainTransparentLogo[chain.id]?.({
            width: '14',
            height: '14',
          })}
          <span>{chain.name} Network</span>
        </div>
      </div>
      <div className="relative w-full">
        <input
          className={classNames(
            'peer w-full break-all border-b bg-transparent pb-2 font-aeonikMono text-14 outline-none transition-[border-color] placeholder:text-cf-light-1 focus:placeholder:text-transparent',
            showError
              ? 'border-cf-red-1 hover:border-cf-red-1 focus:border-cf-red-1'
              : 'border-cf-gray-5 hover:border-cf-light-1 focus:border-cf-light-2',
            address === '' &&
              'text-cf-gray-5 caret-cf-light-4 before:cursor-text before:content-[attr(data-placeholder)]',
          )}
          placeholder={`Enter a valid ${chain.name} address`}
          onChange={onChange}
          spellCheck={false}
          value={address}
        />
        <span
          className={classNames(
            'absolute -bottom-5 left-0 text-12 text-cf-red-1/75 transition-all peer-hover:text-cf-red-1/90 peer-focus:text-cf-red-1',
            !showError && 'opacity-0',
          )}
        >
          Enter a valid {chain.name} address
        </span>
      </div>
    </div>
  );
}
