import { Fragment } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Button } from '@/shared/components';

type NavbarDropdownItem = {
  separator?: React.ReactNode;
  content: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

type NavbarDropdownProps = {
  title: React.ReactNode;
  items: NavbarDropdownItem[];
  isActive: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const animate = {
  enter: {
    opacity: 1,
    scale: 1,
    y: '0px',
    transition: {
      duration: 0.15,
    },
    display: 'block',
  },
  exit: {
    opacity: 0,
    scale: 1,
    y: '-8px',
    transition: {
      duration: 0.15,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

const NavbarDropdown = ({
  title,
  items,
  isActive,
  onMouseEnter,
  onMouseLeave,
}: NavbarDropdownProps) => (
  <motion.div onMouseLeave={onMouseLeave}>
    <motion.div
      onMouseEnter={onMouseEnter}
      className={classNames('flex cursor-default items-center', isActive && 'text-cf-white')}
    >
      {title}
    </motion.div>
    <motion.div
      initial="exit"
      animate={isActive ? 'enter' : 'exit'}
      variants={animate}
      className="absolute ml-[-30px] flex flex-col space-y-[5px]"
    >
      <div className="flex w-[188px] flex-col space-y-2 rounded-md border border-cf-gray-4 bg-cf-gray-3-5 p-2.5 text-14 text-cf-white">
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            {item.separator}
            {item.onClick ? (
              <Button
                type="link-secondary"
                onClick={item.onClick}
                className="!justify-start !p-0"
                size="small"
                disabled={item.disabled}
              >
                {item.content}
              </Button>
            ) : (
              <div className="min-h-[32px]">{item.content}</div>
            )}
          </Fragment>
        ))}
      </div>
    </motion.div>
  </motion.div>
);

export default NavbarDropdown;
